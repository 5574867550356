<template>
  <div>
    <ManageBanners_9 />
  </div>
</template>
<script>
import ManageBanners_9 from "@/components/Banners_9/ManageBanners_9";
export default {
  components: {
    ManageBanners_9,
  },
  created() {
  },
};
</script>
